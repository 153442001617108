@@ -1,6 +1,21 @@
<template>
    <div>
        <nuxt />
        <client-only>
            <cookie />
        </client-only>
    </div>
</template>

<script>
import Cookie from '~/components/cookie.vue';

export default {
    components: {
        Cookie,
    },
};
</script>

<style lang="scss">
@import '../node_modules/@fontsource/source-sans-pro/300.css';
@import '../node_modules/@fontsource/source-sans-pro/400.css';
@import '../node_modules/@fontsource/source-sans-pro/600.css';
@import '../node_modules/@fontsource/source-sans-pro/700.css';

html {
    font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont,
        'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    font-size: 16px;
    word-spacing: 1px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
}

body {
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

.container {
    @include container;
}

.slide-down-enter-active {
    transition: all 0.25s ease;
}
.slide-down-leave-active {
    margin-top: 0;
    transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-down-enter,
.slide-down-leave-to {
    margin-top: -15px;
    opacity: 0;
}
</style>
