@@ -0,0 +1,190 @@
<template>
    <div v-if="visible" class="cookie__overlay">
        <div class="cookie">
            <div class="container">
                <el-row
                    type="flex"
                    :gutter="60"
                    align="middle"
                    class="flex-wrap"
                >
                    <el-col :xs="24" :sm="12" :md="16">
                        <div class="cookie__title">
                            Wir benötigen Ihre Zustimmung
                        </div>
                        <div class="cookie__text">
                            Wir nutzen Cookies auf unserer Webseite. Einige sind
                            essenziell, andere helfen uns das Nutzererlebnis zu
                            verbessern (Analyse-Cookies) -

                            <nuxt-link
                                to="/datenschutz"
                                class="cookie__text-link"
                                >Hier erfahren Sie alles zum
                                Datenschutz</nuxt-link
                            >.
                        </div>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="8">
                        <div>
                            <el-checkbox
                                class="w100"
                                label="Essenziell"
                                border
                                size="medium"
                                disabled
                                checked
                            ></el-checkbox>
                        </div>

                        <div class="mt-xxs">
                            <el-checkbox
                                v-model="statistic"
                                class="w100"
                                label="Statistik"
                                border
                                size="medium"
                            ></el-checkbox>
                        </div>

                        <el-row type="flex" class="mt-s">
                            <el-button
                                class="w100"
                                size="medium"
                                @click="acceptCookie('choice')"
                            >
                                Speichern
                            </el-button>

                            <el-button
                                class="w100"
                                type="primary"
                                icon="el-icon-success"
                                size="medium"
                                @click="acceptCookie('all')"
                            >
                                Alle akzeptieren
                            </el-button>
                        </el-row>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
import { bootstrap } from 'vue-gtag';

export default {
    data() {
        return {
            visible: true,
            statistic: false,
        };
    },

    created() {
        this.isVisible();
    },

    methods: {
        /**
         * Blendet den Cookie-Hinweis ein oder aus
         *
         * @return void
         */
        isVisible() {
            if (this.$cookies.get('acceptCoppioCookies') === 'essential') {
                this.$gtag.optOut();
                this.visible = false;
                return;
            }
            if (this.$cookies.get('acceptCoppioCookies') === 'all') {
                bootstrap().then(() => {
                    this.$gtag.optIn();
                    this.visible = false;
                });
                return;
            }
            this.visible = true;
        },

        /**
         * Setzt einen Cookie für das Akzeptieren
         */
        acceptCookie(type) {
            let cookies = '';
            if (type === 'all' || this.statistic) {
                cookies = 'all';
                bootstrap().then(() => {
                    this.$gtag.optIn();
                });
            } else {
                cookies = 'essential';
                this.$gtag.optOut();
            }
            this.$cookies.set('acceptCoppioCookies', cookies, {
                path: '/',
            });
            this.visible = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.cookie {
    position: fixed;
    z-index: 201;
    left: 0;
    bottom: 0;
    width: 100%;
    background: white;
    box-sizing: border-box;
    padding: 20px 0;

    @include breakpoint(xs) {
        padding: 30px 0;
    }

    &__overlay {
        position: fixed;
        z-index: 200;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
    }

    &__title {
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 10px;

        @include breakpoint(xs) {
            font-size: 20px;
            padding-bottom: 15px;
        }
    }

    &__text {
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 20px;
        width: 100%;
        max-width: 525px;

        strong {
            font-weight: 600;
        }

        @include breakpoint(xs) {
            margin-bottom: 0;
            font-size: 15px;
            line-height: 22px;
        }

        &-link {
            color: $soft_black;
            font-weight: 600;
            transition: 0.25s all;
            cursor: pointer;

            &:hover {
                opacity: 0.7;
            }
        }
    }
}
</style>
